import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Hero from './components/Hero';
import About from './components/About';
import Resume from './components/Resume';
import Timeline from './components/Timeline';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './assets/css/Footer.css';
import './assets/css/Navbar.css';

function App() {
  return (
    <Router>
      <div>
        <Nav />
        <Hero />
        <About />
        <Resume />
        <Timeline />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </Router>
  );
}
export default App;
