// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';

const Footer = () => {
    return (
        <footer className="rounded-sm shadow w-full bottom-0 px-4 py-2">
            <div className="flex justify-between items-center">
                <div className="brand-logo">
                    <Link to="/">
                        <img
                            className="logo w-auto h-6 mb-0"
                            src={Logo}
                            alt="Kartik Binzade"
                        />
                    </Link>
                </div>
                <div className="footnote">
                    <p className="text-sm text-white">
                        Kartik Binzade © 2024 All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
