// src/components/Timeline.js
import React from 'react';
import {
    Button,
    TimelineBody,
    TimelineContent,
    TimelineItem,
    TimelinePoint,
    TimelineTime,
    TimelineTitle,
} from 'flowbite-react';
import { Timeline as TimeLine } from 'flowbite-react';
import { HiArrowNarrowRight } from 'react-icons/hi';

function Timeline() {
    return (
        <section className="py-12" id="timeline">
            <div className="container mx-auto" >
                <h2 className="text-3xl font-bold text-center mb-10">Timeline</h2>
                <TimeLine className='xl:mx-48 mx-20'>
                    <TimelineItem>
                        <TimelinePoint />
                        <TimelineContent>
                            <TimelineTime>Feb 2021 - Dec 2023 | B.E. in Computer Engineering</TimelineTime>
                            <TimelineBody>RTMNU</TimelineBody>
                            <TimelineBody className='text-sm'>Pursued Bachelor of Engineering in Computer Engineering, covering a comprehensive curriculum in computer science and engineering.</TimelineBody>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelinePoint />
                        <TimelineContent>
                            <TimelineTime>Feb 2022 | CCNP Enterprise</TimelineTime>
                            <TimelineBody>
                                Networkers Home, Bangalore
                            </TimelineBody>
                            <TimelineBody className='text-sm'>Completed the Cisco Certified Network Professional (CCNP) Enterprise certification program at Networkers Home, Bangalore. Gained expertise in advanced networking concepts and technologies.</TimelineBody>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelinePoint />
                        <TimelineContent>
                            <TimelineTime>Jul 2022 - Oct 2022 | AWS Cloud Intern</TimelineTime>
                            <TimelineBody>F13 Technologies</TimelineBody>
                            <TimelineBody className='text-sm'>Engaged in a hands-on internship as an AWS Cloud Intern, acquiring skills in problem-solving, team leadership, and gaining experience with Amazon Web Services (AWS), focusing on services like Amazon S3 and network security.</TimelineBody>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelinePoint />
                        <TimelineContent>
                            <TimelineTime>Jul 2022 - Sep 2022 | Full Stack Web Developer</TimelineTime>
                            <TimelineBody>RATHOD IT SERVICES</TimelineBody>
                            <TimelineBody className='text-sm'>Undertook an internship position as a Full Stack Web Developer, gaining proficiency in MongoDB, React.js, Django, and full-stack development technologies.</TimelineBody>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelinePoint />
                        <TimelineContent>
                            <TimelineTime>Nov 2021 | Diploma in Electrical Engineering</TimelineTime>
                            <TimelineBody>
                                MSBTE
                            </TimelineBody>
                            <TimelineBody className='text-sm'>Attained a Diploma in Electrical Engineering, adding a diverse skill set to the technical background.</TimelineBody>
                        </TimelineContent>
                    </TimelineItem>

                </TimeLine>
            </div>
        </section>
    );
};

export default Timeline;