// src/components/Projects.js
import React from 'react';
import { Carousel } from 'flowbite-react';
import observer from '../images/Landing_ob.png';
import P1 from '../images/mceml.png';
import P2 from '../images/dashboard.png';
import P3 from '../images/dms.png';
import P4 from '../images/homelyvista.png';
import P5 from '../images/restro.jpg';

const Projects = () => {
    return (
        <section className="py-12" id="projects">
            <div className="container mx-auto">
                <h2 className="text-3xl font-bold mb-6 text-center">Projects</h2>
                <div className="w-full md:w-auto lg:w-1/2 xl:w-11/12 mx-auto h-96 overflow-hidden px-8">
                    <Carousel
                        className="w-auto xl:px-80"
                        slidesPerView={1}
                        slideInterval={2000}
                        pauseOnHover
                        breakpoints={[
                            { minWidth: 640, slidesPerView: 2 },
                            { minWidth: 768, slidesPerView: 3 },
                            { minWidth: 1024, slidesPerView: 4 },
                        ]}
                    >
                        <div className="flex items-center justify-center text-white">
                            <img className="w-fit" src={observer} alt="The Observer" />
                        </div>
                        <div className="flex items-center justify-center text-white">
                            <img className="w-fit" src={P1} alt="Code League" />
                        </div>
                        <div className="flex items-center justify-center text-white ">
                            <img className="w-fit" src={P2} alt="dashboard design" />
                        </div>
                        <div className="flex items-center justify-center text-white">
                            <img className="w-fit" src={P3} alt="Department Management System" />
                        </div>
                        <div className="flex items-center justify-center text-white">
                            <img className="w-fit" src={P4} alt="Homely Vista" />
                        </div>
                        <div className="flex items-center justify-center text-white">
                            <img className="w-fit" src={P5} alt="Restro" />
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
};

export default Projects;
