// src/components/Nav.js
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Logo from '../images/logo.png';

const navigation = [
    { name: 'Home', to: 'home', current: true },
    { name: 'About', to: 'about', current: false },
    { name: 'Resume', to: 'resume', current: false },
    { name: 'Timeline', to: 'timeline', current: false },
    { name: 'Projects', to: 'projects', current: false },
    { name: 'Contact', to: 'contact', current: false },
];

function Nav() {
    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    return (
        <div className="page-header">
            <div className="navbar-container">
                <RouterLink to="/" className="brand-logo" onClick={scrollToTop}>
                    <img className="logo h-10 w-auto" src={Logo} alt="Kartik Binzade" />
                </RouterLink>
                <Disclosure as="nav" className="nav-links">
                    {({ open }) => (
                        <div className={`menu ${open ? 'open' : ''}`}>
                            {navigation.map((item) => (
                                <ScrollLink
                                    key={item.name}
                                    to={item.to}
                                    spy={true}
                                    smooth={true}
                                    offset={-70} // Adjust the offset based on your layout
                                    duration={400}
                                    className={`nav-link ${item.current ? 'current' : ''}`}
                                >
                                    {item.name}
                                </ScrollLink>
                            ))}
                        </div>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}

export default Nav;
