import React from 'react';
import resumeImage from '../images/resume.png';
import resumePDF from '../files/kartik-binzade.pdf'; // Replace with the actual path to your PDF file

const Resume = () => {
    return (
        <section className="py-12" id="resume">
            <div className="container mx-auto">
                <h2 className="text-3xl font-bold mb-16 text-center">Resume</h2>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 self-center">
                    <div className="gap-4 flex justify-center">
                        <div className="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="dark" data-type="HORIZONTAL" data-vanity="kartik-binzade" data-version="v1">
                            <a className="badge-base__link LI-simple-link" href="https://in.linkedin.com/in/kartik-binzade?trk=profile-badge"></a>
                        </div>
                    </div>
                    <div className="gap-4 flex justify-center relative">
                        <a
                            href={resumePDF}
                            download="kartik-binzade.pdf"
                            className="w-2/4 h-auto hover:border-indigo-600 hover:border-2"
                        >
                            <img src={resumeImage} alt="resume" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Resume;
