import React from 'react';
import { Badge } from 'flowbite-react';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

const skillsList = [
    'React', 'Django', 'Postgres', 'Python', 'ML', 'AI', 'Ansible', 'Power automate', 'Malware Analysis', 'TCP/IP', 'OSPF', 'BGP', 'DNS', 'DHCP', 'ARP', 'LAN', 'VLAN', 'WAN', 'IP Addressing',
    'IPsec', 'FTP', 'TFTP', 'SSH', 'TELNET', 'Wireshark', 'VPN', 'FIREWALL', 'AWS', 'VPN', 'Troubleshooting', 'RIP', 'EIGRP', 'Switching', 'STP', 'PPPoE',
    'SMTP', 'Routing', 'OS/Server installation', 'UBUNTU SERVER'
];
const interest = [
    'Automation', 'Learn new skills', 'Reading', 'Listen To Podcasts', 'Stargaze', 'Writing Quotes', 'Gardening', 'Investing', 'Practice Meditation'
];

const About = () => {
    return (
        <>
            <div className="px-12 py-12" id="about">
                <div className="overflow-hidden mx-auto bg-white">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="lg:pr-8 lg:pt-4">
                                <div className="lg:max-w-lg">
                                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Create wisely</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Let Me Introduce Myself</p>
                                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                <li className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                                            </dt>
                                            <dd className="inline">Hi! My name is Kartik Binzade, born in 1999 and grew up in a small town in Maharashtra, India. I find joy in sipping a warm cup of tea, delving into books and exploring anything tech-related.</dd>
                                            <br />
                                            <p className='py-2'></p>
                                            <li className="absolute left-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                                            <dd className="inline">Starting my journey at the age of 20, I navigated through books, e-notes and online courses. Now, as a Developer, I'm dedicated to crafting clean, elegant and efficient code.</dd>
                                            <br />
                                            <p className='py-2'></p>
                                            <li className="absolute left-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                                            <dd className="inline">Curious about my skills and creativity? Dive into my portfolio below, I'm confident you'll appreciate what you find. Take a look at my best work and if you're fascinated, feel free to reach out. <br /> <a href="https://www.linkedin.com/in/kartik-binzade" className='text-indigo-600'>Let's connect!</a></dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <script type="module" src="https://unpkg.com/@splinetool/viewer@1.0.24/build/spline-viewer.js"></script>
                            <iframe src='https://my.spline.design/roomrelaxingcopy-889b66da6a8f338061e7744106416c18/' frameborder='0' width='100%' height='100%'></iframe>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-7 gap-24 mt-16">
                    <section className="md:col-span-3 gap-4 p-4 rounded-md">
                        <div>
                            <h1 className="text-2xl font-bold mb-8">Skills</h1>
                            <div className="flex flex-wrap grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                {skillsList.map((skill, index) => (
                                    <Badge key={index} color="indigo">{skill}</Badge>
                                ))}
                            </div>
                        </div>
                    </section>
                    <section className="md:col-span-4 gap-4 p-4 rounded-md">
                        <div>
                            <h1 className="text-2xl font-bold mb-8">Interests & Habits</h1>
                            <div className="grid grid-cols-3 gap-1">
                                {interest.map((interest, index) => (
                                    <div key={index} className="box p-4 rounded-md">
                                        <p className="text-sm font-semibold">{index + 1} {interest}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>

    );
};

export default About;
